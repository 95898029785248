.wrapper{
    width: 50%;
    margin: 10px auto;
    .row {
        margin-left: 0;
        margin-right: 0;
    }
    label {
        width: 100%;
        padding: 20px 0 10px;
    }
    input {
        border-radius: 50px;
        height: 50px !important;
        border: 3px solid hsl(0, 0%, 80%);
        display: flex;
        line-height: 35;
        width: 100%;
        padding: 0 20px;
        align-items: start;
    }
    :focus-visible {
        border: 3px solid #ccc;
    }
    .btn-submit {
        text-align: center;
        margin: 0 auto;
        width: 22%;
        display: flex;
        margin-bottom: 3rem;
        align-items: center;
        line-height: 35px;
        margin-top: 6rem;
        justify-content: center;
        border-radius: 40px;
        background-color: #dd16c3;
        box-shadow: 0px 0px 25px #cb39bd;
        border-color: #dd16c3;
        color: #fff;
        font-weight: 500;
        font-size: 18px;
    }
    .year-month {
        display: flex;
        width: 85%;
        label {
            display: block;
            padding: 20px 0 0px 0;
            width: 57%;
        }
        .css-b62m3t-container {
            position: relative;
            top: 55px;
            width: 133rem;
            left: -55px;
            right: 37px;
            .css-13cymwt-control {
                height: 50px;
                border-width: 3px;
                border-radius: 50px;
                .css-1fdsijx-ValueContainer {
                    height: 40px;
                    border-width: 3px;
                    border-radius: 50px;
                    padding:  0 20px;
                    .css-1dimb5e-singleValue {
                        line-height: 30px !important;
                    }
                    .css-qbdosj-Input {
                        line-height: 30px !important;
                    }
                }
                .css-1u9des2-indicatorSeparator {
                    width: 0px !important;
                }
                .css-1xc3v61-indicatorContainer {
                    padding: 0 8px 1px;
                }
                input {
                    height: 28px !important;
                    line-height: 30px !important;
                }
            }
            .css-t3ipsp-control {
                height: 50px;
                line-height: 30px;
                border-width: 3px;
                border-color: #ccc;
                border-radius: 50px;
                min-height: 30px !important;
                .css-1fdsijx-ValueContainer {
                    padding: 0 20px;
                }
                .css-1u9des2-indicatorSeparator {
                    width: 0px !important;
                }
            }
        }
        input {
            height: 30px !important;
        }
        .dont {
            display: block;
            position: absolute;
            width: 11.1%;
            left: 63.9%;
            height: 50px !important;
            top: 22.9rem;
        }
    }
    .payment-form-text {
        p {
            font-family: Ubuntu,medium;
            font-size: 14px;
            padding-left: 10px;
        }
        .payment-block-img {
            width: 100%;
            position: relative;
            overflow: hidden;
            height: 100%;
            img {
                width: 100%;
            }
            .payment-box {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0px;
                z-index: -1;
                min-width: 100%;
                display: block;
            }
            .payment-block-text {
                position: absolute;
                left: 0;
                right: 0;
                text-align: center;
                top: 0;
                padding: 45px 0;   
                h2 {
                    color: #fff;
                    font-size: 34px;
                    line-height: 30px;
                    margin-bottom: 17px;
                    font-family: Ubuntu,bold;
                } 
                p {
                    font-size: 18px;
                    color: #fff;
                    line-height: 30px;
                    padding-left: 0;
                    margin-bottom: 17px;
                    font-family: Ubuntu,medium;
                }
                a {
                    text-decoration: none;
                    font-size: 15px;
                    width: 80%;
                    margin: 0 auto;
                    height: 50px;
                    border-radius: 50px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #DD16C3;
                    background-color: #fff;
                    box-shadow: 0px 3px 6px #00000029;
                }
            }
        }
    }
}




@media screen and (max-device-width: 1192px) {
    .wrapper {
        width: 80%;
        .btn-submit {
            width: 30%;
        }
        .year-month {
            width: 80%;
            .css-b62m3t-container {
                left: -60px;
                .css-13cymwt-control {
                    width: 100%;
                    .css-1fdsijx-ValueContainer {
                        padding: 0 0 0 20px;
                    }
                }
                .css-t3ipsp-control {
                    width: 100%;
                }
            }
            .dont {
                left: 68%;
                width: 257px;
                top: 22.8rem;
            }
        }
   }
}
@media screen and (max-device-width: 1024px)  {
    .wrapper {
        width: 100%;
        padding: 15px 46px;
        .btn-submit {
            width: 30%;
        }
        .year-month {
            width: 82%;
            .css-b62m3t-container {
                left: -60px;
                .css-13cymwt-control {
                    width: 100%;
                    .css-1fdsijx-ValueContainer {
                        padding: 0 0 0 20px;
                    }
                }
                .css-t3ipsp-control {
                    width: 100%;
                }
            }
            .dont {
                left: 72%;
                width: 243px;
                top: 23.8rem;
            }
        }
   }
}

@media screen and (max-device-width: 768px) {
    .wrapper {
        width: 100%;
        padding: 15px 37px;
        .btn-submit {
            width: 30%;
        }
        .year-month {
            width: 80%;
            .css-b62m3t-container {
                left: -46px;
                .css-13cymwt-control {
                    width: 100%;
                    .css-1fdsijx-ValueContainer {
                        padding: 0 0 0 20px;
                    }
                }
                .css-t3ipsp-control {
                    width: 100%;
                }
            }
            .dont {
                left: 72%;
                width: 177px;
                top: 23.8rem;
            }
        }
   }
}



@media screen and (max-device-width: 767px) {
  .wrapper {
        width: 100%;
        padding: 12px;
        .btn-submit {
            width: 100%;
            position: relative;
            top: 44px;
            box-shadow: 0px 0px 10px #cb39bd;
        }
        .year-month {
            width: 75%;
            position: absolute;
            .css-b62m3t-container {
                left: -46px;
                .css-13cymwt-control {
                    width: 125%;
                    .css-1fdsijx-ValueContainer {
                        padding: 0 0 0 13px;
                    }
                }
                .css-t3ipsp-control {
                    width: 124%;
                    .css-1fdsijx-ValueContainer {
                        padding: 0 0px 0 13px; 
                    }
                }
            }
            .dont {
                left: 84%;
                width: 121px;
                top: 55px;
            }
        }
   }
}
