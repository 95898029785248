.footer{
   padding: 40px 30px 30px;
    background: rgb(243,189,28);
    background: linear-gradient(90deg, rgba(243,189,28,1) 0%, rgba(221,26,195,1) 100%);
    .footer-social-icon{
        margin-top: 20px;
       ul{
        display: block;
        padding: 0;
        margin: 0;
        li{
            display: inline-block;
            margin: 0 8px;
        }
       }
    }
    .copyright-text{
        margin-top: 20px;
        p{
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 0;
        }
    }

    .made-with-text {
        font-family: 'AmpleSoft Pro';
        font-size: 1rem;
        line-height: 1rem;
        font-weight: normal;
        color: #fff;
        margin-top: 10px;
        p {
            margin-bottom: 0;
        }
        a {
            font-size: 1rem;
            line-height: 1rem;
            font-weight: normal;
            color: #fff;
            text-decoration: none;
            transition: all 0.3s;
            &:hover {
                color: #000;
            }
        }
        em {
            width: 0.8rem;
            display: inline-block;
            margin: 0 0.5rem;
            img{
                animation: plus-heart 1s infinite;
                -webkit-animation: plus-heart 1s infinite;
            }
        }
    }
}


@keyframes plus-heart {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }
}

@-webkit-keyframes plus-heart {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }
}

@-moz-keyframes plus-heart {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }
}