@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@font-face {
  font-family: 'AmpleSoft Pro';
  src: local('AmpleSoft Pro'), url('./assets/fonts/AmpleSoftPro-Regular.ttf'), url('./assets/fonts/AmpleSoftPro-Regular.woff'), url('./assets/fonts/AmpleSoftPro-Regular.eot');
}


body {
  font-family: 'Lato', sans-serif;
}