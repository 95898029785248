img {
    max-width: 100%;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
}

.main {
    .vid-banner-sec {
        position: relative;
        max-height: 300px;
        min-height: 300px;
        overflow: hidden;
        .banner-content {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
           background: rgb(221, 26, 195);
           background: linear-gradient(180deg, rgba(221, 26, 195, 0.6) 0%, rgba(243, 189, 28, 0.4) 100%);
            .banner-inner-content {
                padding: 0 15px;
                h2 {
                    font-size: 60px;
                    line-height: 65px;
                    font-weight: 700;
                    color: #ffffff;
                    margin-bottom: 20px;
                    font-family: 'Ubuntu', sans-serif;
                    text-shadow: 0 0 6px rgba(0, 0, 0, .23);
                    text-align: center;
                }
                p {
                    font-size: 36px;
                    line-height: 40px;
                    font-weight: 600;
                    color: #ffffff;
                    font-family: 'Lato', sans-serif;
                    margin: 0;
                    text-align: center;
                    text-shadow: 0 0 6px rgba(0, 0, 0, .2);
                }
            }
        }
    }
    // .main-banner {
    //     background-image: url(../../assets/img/banner-image.png);
    //     background-repeat: no-repeat;
    //     width: 100%;
    //     height: 100%;
    //     background-size: cover;
    //     .banner-section {
    //         padding: 80px 30px;
    //         background: rgb(221, 26, 195);
    //         background: linear-gradient(180deg, rgba(221, 26, 195, 0.8) 0%, rgba(243, 189, 28, 0.8) 100%);
    //         .banner-content {
    //             h2 {
    //                 font-size: 60px;
    //                 line-height: 65px;
    //                 font-weight: 700;
    //                 color: #ffffff;
    //                 margin-bottom: 20px;
    //                 font-family: 'Ubuntu', sans-serif;
    //                 text-shadow: 0 0 6px rgba(0, 0, 0, .23);
    //             }
    //             p {
    //                 font-size: 36px;
    //                 line-height: 40px;
    //                 font-weight: 600;
    //                 color: #ffffff;
    //                 font-family: 'Lato', sans-serif;
    //                 margin: 0;
    //             }
    //         }
    //     }
    // }
    .launching-soon-section {
        padding: 50px 0;
        .launching-heading {
            margin-bottom: 50px;
            h3 {
                font-size: 30px;
                line-height: 40px;
                font-weight: 500;
                color: #dd16c3;
                font-family: "Ubuntu", sans-serif;
            }
        }
        .launching-boxs {
            .launching-box {
                padding: 20px;
                .image {
                    img {
                        width: 115px;
                        height: 115px;
                    }
                }
                .launching-content {
                    padding-top: 20px;
                    .frist-name {
                        font-size: 20px;
                        line-height: 26px;
                        font-weight: 600;
                        color: #dd16c3;
                        min-height: 40px;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                    }
                    .discription {
                        font-size: 18px;
                        line-height: 22px;
                        font-weight: 400;
                        color: #585858;
                    }
                    .last-name {
                        color: #5090bb;
                    }
                }
            }
        }
    }
    .revolution-buttons {
        .lovehappiness-btn {
            margin-bottom: 50px;
            padding: 32px;
            font-size: 36px;
            line-height: 1.2;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 500;
            border-radius: 25px;
            border: none;
            width: 100%;
            background: rgb(221, 26, 195);
            background: linear-gradient(90deg, rgba(221, 26, 195, 1) 30%, rgba(7, 37, 119, 1) 100%);
        }
        .find-btn {
            font-size: 18px;
            font-weight: 600;
            padding: 10px 48px;
            border-radius: 25px;
            background-color: #dd16c3;
            box-shadow: 0px 0px 20px #cb39bd;
            border: none;
        }
        .download-app-btn {
            display: none;
        }
    }
    .consectetur-section {
        width: 100%;
        .consectetur-left {
            background: linear-gradient(180deg, rgba(15, 87, 146, 0.7) 35%, rgba(243, 189, 28, 1) 100%);
            position: relative;
            .consectetur-left-content {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .consectetur-left-inner {
                    width: 70%;
                    margin-left: 15%;
                    text-align: left;
                    p {
                        font-size: 26px;
                        line-height: 1.5;
                        font-weight: 400;
                        color: #ffffff;
                    }
                    .app-btn {
                        margin-top: 30px;
                        .find-btn {
                            padding: 10px 25px;
                            font-size: 18px;
                            font-weight: 500;
                            border-radius: 25px;
                            background-color: #dd16c3;
                            box-shadow: 0px 0px 10px #cb39bd;
                            border-color: #dd16c3;
                        }
                    }
                }
            }
        }
    }
    .faq-accodion {
        background: rgb(33, 61, 132);
        background: linear-gradient(180deg, rgba(33, 61, 132, 1) 20%, rgba(33, 61, 132, 0.7) 100%);
        background: linear-gradient(180deg, #213d84 20%, rgba(19, 119, 158, 0.7) 100%);
        .faq-accodion-inner {
            background-image: url(../../assets/img/faq-background-img.png);
            background-repeat: no-repeat;
            background-size: cover;
            padding: 90px 0;
            .container {
                max-width: 870px;
            }
            .heading {
                margin-bottom: 30px;
                h4 {
                    font-size: 25px;
                    line-height: 30px;
                    font-weight: 500;
                    color: #ffffff;
                }
            }
            .accordion-main-section {
                .accordion-item {
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid #8CA4C4;
                    border-radius: 0;
                    .accordion-header {
                        .accordion-button {
                            font-size: 22px;
                            font-weight: 400;
                            color: #ffffff;
                            background: transparent;
                            box-shadow: none;
                            align-items: flex-start;
                            padding: 2rem 0 2rem;
                            padding-right: 50px;
                            position: relative;
                            letter-spacing: 0.8px;
                            &:focus {
                                box-shadow: none;
                            }
                            &::after {
                                background-image: url(../../assets/img/minus-icon.png);
                                background-repeat: no-repeat;
                                position: absolute;
                                right: 0;
                            }
                            &.collapsed::after {
                                background-image: url(../../assets/img/plus-icon.png);
                                background-repeat: no-repeat;
                                position: absolute;
                                right: 0;
                            }
                        }
                    }
                    .accordion-body {
                        font-size: 16px;
                        line-height: 1.5;
                        font-weight: 400;
                        color: #ffffff;
                        padding: 0;
                        margin-bottom: 2rem;
                        opacity: 0.9;
                    }
                }
            }
            .accordion-btn {
                .find-btn {
                    padding: 10px 40px;
                    font-size: 18px;
                    font-weight: 500;
                    border-radius: 25px;
                    background-color: #dd16c3;
                    box-shadow: 0px 0px 10px #cb39bd;
                    border-color: #dd16c3;
                }
            }
        }
    }
}

@media screen and (max-device-width: 991px) {
    .main {
        .vid-banner-sec {
            .banner-content {
                h2 {
                    font-size: 50px;
                    line-height: 56px;
                }
                p {
                    font-size: 30px;
                    line-height: 36px;
                }
            }
        }
        .launching-soon-section {
            .launching-boxs {
                .launching-box {
                    padding: 0;
                }
            }
        }
    }
}

@media screen and (max-device-width: 767px) {
    .main {
        .revolution-buttons {
            .find-btn {
                display: none;
            }
            .download-app-btn {
                display: block;
                width: 100%;
                padding: 10px 25px;
                font-size: 18px;
                font-weight: 500;
                border-radius: 25px;
                background-color: #dd16c3;
                box-shadow: 0px 0px 10px #cb39bd;
                border-color: #dd16c3;
            }
        }
        .faq-accodion {
            .faq-accodion-inner {
                padding: 60px 0;
                .accordion-btn {
                    .find-btn {
                        width: 100%;
                    }
                }
            }
        }
        .consectetur-section {
            .my-row {
                flex-direction: column-reverse;
            }
            .consectetur-left {
                .consectetur-left-content {
                    .consectetur-left-inner {
                        p {
                            text-align: center;
                            line-height: 1.3;
                        }
                        width: 90%;
                        margin-left: 0;
                        margin: auto;
                        .app-btn {
                            .find-btn {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-device-width: 540px) {
    .main {
        .vid-banner-sec {
            .banner-video {
                width: auto;
                height: 300px;
            }
            .banner-content {
                .banner-inner-content {
                    h2 {
                        font-size: 42px;
                        line-height: 45px;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 24px;
                    }
                }
            }
        }
        .launching-soon-section {
            padding: 25px 0;
            .launching-heading {
                margin-bottom: 20px;
                h3 {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
            .launching-boxs {
                .launching-box {
                    margin-bottom: 30px;
                    .image {
                        img {
                            width: 65px;
                            height: 65px;
                        }
                    }
                    .launching-content {
                        padding-top: 10px;
                        .frist-name {
                            font-size: 16px;
                            line-height: 20px;
                            margin-bottom: 8px;
                        }
                        .discription {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
        .revolution-buttons {
            .lovehappiness-btn {
                margin-bottom: 25px;
                padding: 15px;
                font-size: 16px;
                line-height: 22px;
                border-radius: 17px;
            }
        }
        .faq-accodion {
            .faq-accodion-inner {
                .accordion-main-section {
                    .accordion-item {
                        .accordion-header {
                            .accordion-button {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}