.success-main {
    .success-banner {
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 100%;
        img {
            width: 100%;
        }
        .box-shadow {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: -33px;
            z-index: 0;
            min-width: 106%;
            display: block;
        }
        .success-img {
            position: absolute;
            top: 100px;
            margin: 0 auto;
            left: 0;
            display: block;
            text-align: center;
            right: 0;
            img {
                width: 165px;
                height: auto;
            }
            .right-success {
                width: 71px !important;
                position: absolute;
                top: 41px;
                left: 0;
                margin: 0 auto;
                right: 0;
            }
            .success-text {
                h1 {
                    color: #F3BD1C;
                    font-size: 45px;
                    font-family: Ubuntu,Bold;
                    line-height: 50px;
                   display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 20px;
                }
                p {
                    color: #fff;
                    font-size: 22px;
                    line-height: 33px;
                    font-family: Ubuntu;
                    display: flex;
                  align-items: center;
                  margin-bottom: 45px;
                  justify-content: center;
                }
                .accordion-btn {
                    .btn-primary {
                        width: 100%;
                        height: 57px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                        font-weight: 500;
                        border-radius: 50px;
                        background-color: #dd16c3;
                        box-shadow: 0px 0px 10px #cb39bd;
                        border-color: #dd16c3;
                        img {
                            width: 160px;
                            height: 32px;
                            margin-left: 14px;
                        }
                    }
                }
    
            }
        }
    }
}


@media screen and (max-device-width: 767px) {
    .success-main {
        .success-banner {
            img {
                width: 100%;
                height: 712px;
            }
            .box-shadow {
                margin: -10px;
                min-width: 106%;
                height: auto;
                
            }
            .success-text {
                padding: 30px 0 0;
                margin: 0 auto;
                width: 85%;
                h1 {
                    color: #F3BD1C;
                    font-size: 45px;
                    font-family: Ubuntu,Bold;
                    line-height: 50px;
                   display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 20px;
                }
                p {
                    color: #fff;
                    font-size: 22px;
                    line-height: 33px;
                    font-family: Ubuntu;
                    display: flex;
                  align-items: center;
                  margin-bottom: px;
                  justify-content: center;
                }
                .accordion-btn {
                    .btn-primary {
                        width: 100%;
                        height: 57px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                        font-weight: 500;
                        border-radius: 50px;
                        background-color: #dd16c3;
                        box-shadow: 0px 0px 10px #cb39bd;
                        border-color: #dd16c3;
                        img {
                            width: 160px;
                            height: 32px;
                            margin-left: 14px;
                        }
                    }
                }
    
            }
        }
    }
}

